import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col, Icon } from "antd";

import {
  Layout,
  SEO,
  ContainerContentFixed,
  ContentServiceTargetAudience,
  ContentServiceDescription,
  ContentServiceModule,
  ContentServiceSignUp,
  ContainerBackgroundWhite,
} from "../../components";

import { BannerStandard } from "../../components/banners";
import { ListCheckMarks } from "../../components/lists";
import { ContainerBackground } from "../../components/containers";
import { ServicesData } from "../../components/data";
import { Colors } from "../../components/constants";
import { InternalLinkTypes } from "../../components/links";

const Aeg = () => {
  const data = useStaticQuery(graphql`
    query {
      ImgDaegLeftRight: file(
        relativePath: { eq: "services/dhwaeg/daeg_left_right.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgDaegSummaryFront: file(
        relativePath: { eq: "services/dhwaeg/daeg_summary_front.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgWaegSummmary: file(
        relativePath: { eq: "services/dhwaeg/waeg_summary.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ImgDaegSummary: file(
        relativePath: { eq: "services/dhwaeg/daeg_summary.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const ImgDaegLeftRight = ({ style }) => {
    return (
      <Img fluid={data.ImgDaegLeftRight.childImageSharp.fluid} style={style} />
    );
  };

  const ImgDaegSummaryFront = ({ style }) => {
    return (
      <Img
        fluid={data.ImgDaegSummaryFront.childImageSharp.fluid}
        style={style}
      />
    );
  };

  const ImgWaegSummmary = ({ style }) => {
    return (
      <Img fluid={data.ImgWaegSummmary.childImageSharp.fluid} style={style} />
    );
  };
  const ImgDaegSummary = ({ style }) => {
    return (
      <Img fluid={data.ImgDaegSummary.childImageSharp.fluid} style={style} />
    );
  };

  const service = ServicesData["aeg"];

  return (
    <Layout pageLink={InternalLinkTypes.ServicesAEG}>
      <SEO
        title={service.title}
        description={"See the expected generation of other stations"}
      />
      <BannerStandard title={service.title} subTitle={service.description} />
      <ContentServiceDescription
        image={<ImgDaegLeftRight style={{ margin: "auto" }} />}
        description={
          <div>
            <h2
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.17rem",
              }}
            >
              See the expected generation of other stations
            </h2>
            <h3
              style={{
                color: Colors.LIGHT_BLUE,
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              Our Day and Hour Ahead Expected Generation DAEG/HAEG applies a set
              of algorithms to NEM data to derive the expected generation levels
              over the p5min and predispatch time horizon.
            </h3>
            <ListCheckMarks
              items={[
                "predispatch, p5min and p7day generation profiles",
                "updated every 5 and 30 minutes",
                "download data via API",
              ]}
            />
            <h3
              style={{
                color: Colors.DARK_BLUE,
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              Knowing expected generation means you now know all of the
              assumptions behind AEMO’s price forecasts.
            </h3>
            <p>
              This allows you to assess the expected utility of price sensitive
              decisions such as unit availability, dispatch pricing, fuel
              procurement and contract trading opportunities.
            </p>
          </div>
        }
      />

      <ContentServiceTargetAudience
        freeTrialLink={service.freeTrialLink}
        targetAudienceList={service.targetAudienceList}
      />

      <ContentServiceModule
        descriptionList={[
          "5 minutes and half hour resolution of expected generation of either units, stations or local areas.",
          "Updated every half hour and every 5 minutes shortly after predispatch/p5min data is published.",
          "This is not a simulation! Our answers are both accurate and precise – all of the time.",
          "Consistent with predispatch/p5min inputs.",
          "Simple and easy to navigate.",
          "Summary view, deltas and charts.",
        ]}
        title={"DAEG / HAEG"}
        subTitle={"Predispatch and p5min generation profiles"}
        image={<ImgDaegSummary />}
        isBackgroundWhite={true}
        isImageRightAligned={false}
      />

      <ContainerBackground
        style={{
          textAlign: "center",
          padding: "2rem 10%",
          background: Colors.LIGHTER_BLUE,
          //  color: "white",
        }}
      >
        <ContainerContentFixed>
          <Row gutter={32}>
            <Col md={6}>
              <Icon
                style={{ fontSize: "40px", color: Colors.LIGHT_BLUE }}
                type={"api"}
              />
              <p style={{ fontSize: "18px" }}>
                Download data to your database via API access
              </p>
            </Col>
            <Col md={6}>
              <Icon
                style={{ fontSize: "40px", color: Colors.LIGHT_BLUE }}
                type={"cloud-sync"}
              />
              <p style={{ fontSize: "18px" }}>Cloud hosted</p>
            </Col>{" "}
            <Col md={6}>
              <Icon
                style={{ fontSize: "40px", color: Colors.LIGHT_BLUE }}
                type={"team"}
              />
              <p style={{ fontSize: "18px" }}>Domain based subscription</p>
            </Col>
            <Col md={6}>
              <Icon
                style={{ fontSize: "40px", color: Colors.LIGHT_BLUE }}
                type={"customer-service"}
              />
              <p style={{ fontSize: "18px" }}>
                Live chat support during business hours
              </p>
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerBackground>
      <ContentServiceModule
        descriptionList={[
          "Half hour resolution of expected generation of either units, stations or local areas.",
          "Updated 3 times a day shortly after weekly data is published.",
          "This is not a simulation! Our answers are both accurate and precise – all of the time.",
          "Summary table view and charts.",
          "Simple and easy to navigate.",
        ]}
        title={"WAEG"}
        subTitle={"PW7 Generation Profiles"}
        image={<ImgWaegSummmary />}
        isBackgroundWhite={false}
        isImageRightAligned={true}
      />

      <ContentServiceSignUp
        title={service.title}
        freeTrialLink={service.freeTrialLink}
        videoLink={service.watchVideoLink}
      />
      <ContainerBackgroundWhite style={{ padding: "1% 20%" }}>
        <ImgDaegSummaryFront />
      </ContainerBackgroundWhite>

      {/*<ContentServiceResources kbLink={service.kbLink} />*/}
    </Layout>
  );
};

export default Aeg;
